import * as React from 'react';

import UIKit, { ToastPosition, ToastVariant } from '@wartsila/ui-kit';
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQueries,
  useQuery,
} from '@tanstack/react-query';
import { filter } from 'lodash';
import { getFileExtension, removeFileExtension } from './file.utils';

import { Document } from '../documents/documents.types';
import { File } from './file.types';
import { useAuth } from '../auth/auth.hooks';

const MAX_DOWNLOAD_LIMIT = 200;
const NO_FILES_DOWNLOAD = 'No files to download';

export const useMergeFiles = (
  groupId: string,
  documents?: Document[]
): UseQueryResult<QueueDownloadPayload> => {
  const { api } = useAuth();

  let filesIdsToMerge = '';
  const filesToMerge: File[] = [];
  const supportedLanguage = 'en';

  documents?.forEach((document) => {
    const files = filter(document.files, (file) =>
      file.language.includes(supportedLanguage)
    );
    files.forEach((file) => {
      if (file.type === 'application/pdf') filesToMerge.push(file);
      if (file.type === 'text/html') {
        // Remove possible duplicates. If a PDF with the same name exists, use it.
        const isPDFExists = files.find(
          (f) =>
            f.type === 'application/pdf' &&
            removeFileExtension(f.name) === removeFileExtension(file.name)
        );
        if (isPDFExists) return;
        filesToMerge.push(file);
      }
    });
  });

  filesIdsToMerge = filesToMerge
    .flat()
    .map((file) => {
      const fileId = file.id === null ? '' : file.id;
      return fileId.concat(
        `${
          getFileExtension(file.name) ? `.${getFileExtension(file.name)}` : ''
        }`
      );
    })
    .join(',');

  return useQuery(
    ['files', filesIdsToMerge],
    () =>
      api
        .post<QueueDownloadPayload>(
          `/file-queue/${groupId}/merge`,
          { fileIds: filesIdsToMerge },
          {
            params: {
              requestType: 'MergedPDF',
            },
          }
        )
        .then(({ data }) => {
          if (!data.url) {
            throw new Error('Url missing');
          }
          return data;
        }),
    {
      enabled: false,
      refetchOnWindowFocus: false,

      onError: () =>
        UIKit.showToast('Downloading failed', {
          title: 'Error!',
          variant: ToastVariant.Warning,
          position: ToastPosition.BottomRight,
        }),
    }
  );
};

export const useFiles = (
  documents: Document[]
): UseQueryResult<QueueDownloadPayload> => {
  const { api } = useAuth();

  const fileIds: string[] = [];
  const documentIds: string[] = [];

  documents.forEach((document) => {
    if (document.id.includes('grouped_')) {
      fileIds.push(document.id);
      documentIds.push(',');
    } else {
      document.files.forEach((file) => {
        if (file.type !== 'application/pdf') return; // Only limit to PDFs

        fileIds.push(file.id);
        documentIds.push(document.id);
      });
    }
  });

  return useQuery(
    ['files', fileIds],
    () =>
      api
        .get<QueueDownloadPayload>(`/file-queue/${fileIds}/zip`, {
          params: {
            requestType: 'ZIPMultiple',
            articleId: documentIds.toString(),
          },
        })
        .then(({ data }) => {
          if (!data.url) {
            throw new Error('Url missing');
          }
          return data;
        }),
    {
      enabled: false,
      refetchOnWindowFocus: false,

      onError: () =>
        UIKit.showToast('Downloading failed', {
          title: 'Error!',
          variant: ToastVariant.Warning,
          position: ToastPosition.BottomRight,
        }),
    }
  );
};

export const useFilesPost = (): UseMutationResult<
  QueueDownloadPayload,
  unknown,
  Document[],
  unknown
> => {
  const { api } = useAuth();

  return useMutation({
    mutationFn: (documents: Document[]) => {
      const fileIds: string[] = [];
      const documentIds: string[] = [];

      documents.forEach((document) => {
        document.files.forEach((file) => {
          if (
            file.type !== 'application/pdf' ||
            !file.language.includes('en')
          ) {
            return;
          }

          fileIds.push(file.id || `s3://${file.url}`);
          documentIds.push(document.id);
        });
      });

      if (fileIds.length === 0) return Promise.reject(Error(NO_FILES_DOWNLOAD));

      UIKit.showToast(
        `This might take several minutes. Max limit is ${MAX_DOWNLOAD_LIMIT} documents at once.`,
        {
          timeout: 5000,
          variant: ToastVariant.Info,
          title: 'Downloading selected files.',
          position: ToastPosition.BottomRight,
        }
      );

      return api
        .post<QueueDownloadPayload>(`/file-queue/zip`, {
          filesIds: fileIds,
          articlesIds: documentIds,
        })
        .then(({ data }) => {
          if (!data.url) {
            throw new Error('Url missing');
          }
          return data;
        });
    },
    onError: (error) => {
      if (error instanceof Error) {
        if (error.message === NO_FILES_DOWNLOAD) {
          UIKit.showToast(
            'You cannot download single html documents. You need to download the complete manual/spare part catalogue instead.',
            {
              title: 'Error!',
              variant: ToastVariant.Warning,
              position: ToastPosition.BottomRight,
            }
          );
          return;
        }
      }

      UIKit.showToast('Downloading failed', {
        title: 'Error!',
        variant: ToastVariant.Warning,
        position: ToastPosition.BottomRight,
      });
    },
  });
};

type QueueDownloadPayload = {
  id: string;
  status: 'busy' | 'success' | 'error';
  url: string;
};

export const useQueueDownload = ({
  documentId,
  file,
}: {
  file: File;
  documentId: string;
}): UseQueryResult<QueueDownloadPayload> => {
  const { accessToken, api } = useAuth();

  return useQuery(
    ['queue download', documentId, file.id, file.url],
    () =>
      api
        .get<QueueDownloadPayload>(`/file-queue/${file.id}`, {
          params: {
            articleId: documentId,
            s3FileUrl: file.id ? '' : `s3://${file.url}`,
          },
        })
        .then(({ data }) => {
          if (!data.url) {
            throw new Error('Url missing');
          }
          return data;
        }),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: Boolean(accessToken),

      onError: () =>
        UIKit.showToast(`Fetching document content ${documentId} failed`, {
          title: 'Error!',
          variant: ToastVariant.Warning,
          position: ToastPosition.BottomRight,
        }),
    }
  );
};

type TransferStatusPayload = {
  id: string;
  status: 'busy' | 'success' | 'error';
};

export const useTransferStatus = (
  transferId: string | undefined,
  refetchInterval: number | false
): UseQueryResult<TransferStatusPayload> => {
  const { accessToken, api } = useAuth();

  return useQuery(
    ['transfer status', transferId],
    () =>
      api
        .get<TransferStatusPayload>(
          `/file-queue/status/-/${encodeURIComponent(transferId || '')}`
        )
        .then(({ data }) => {
          if (!data.status || data.status === 'error') {
            throw new Error("Status missing or 'error'");
          }
          return data;
        }),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled:
        Boolean(accessToken) && Boolean(transferId) && Boolean(refetchInterval),
      refetchInterval,
      onError: () =>
        UIKit.showToast(`Fetching document content ${transferId} failed`, {
          title: 'Error!',
          variant: ToastVariant.Warning,
          position: ToastPosition.BottomRight,
        }),
    }
  );
};

type Resource = {
  id: string;
  fileType: string;
  resourceId: string;
};

type ResourcesPayload = {
  message: string;
  stackTrace: string;
  statusCode: number;
  payload: Resource[];
};

export const useResources = (
  documentId: string,
  language: string
): {
  isSuccess: boolean;
  data: UseQueryResult<{ url: string; resourceId: string }, unknown>[];
} => {
  const { accessToken, api } = useAuth();

  const resources = useQuery(
    ['resources', documentId, language],
    () =>
      api
        .get<ResourcesPayload>(
          `/documents/${documentId}/resources?language=${language}`
        )
        .then(({ data }) => data.payload),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: Boolean(accessToken),

      onError: () =>
        UIKit.showToast(`Fetching resources for ${documentId} failed`, {
          title: 'Error!',
          variant: ToastVariant.Warning,
          position: ToastPosition.BottomRight,
        }),
    }
  );

  return {
    isSuccess: resources.isSuccess,

    data: useQueries({
      queries: resources.isSuccess
        ? resources.data.map((resource) => ({
            queryKey: ['resource', resource.id],
            staleTime: 1000 * 60 * 30,
            refetchOnWindowFocus: false,
            enabled: Boolean(accessToken),
            queryFn: () =>
              api
                .get(`/file/${resource.id}`, {
                  params: {
                    contentType: resource.fileType,
                    documentId,
                  },
                })
                .then(({ data }) => ({
                  resourceId: resource.resourceId,
                  url: data,
                })),
          }))
        : [],
    }) as UseQueryResult<{
      url: string;
      resourceId: string;
    }>[],
  };
};

export const useRawHTML = (fileId: string): UseQueryResult<string> => {
  const { accessToken, api } = useAuth();

  return useQuery(
    ['html', fileId, 'raw'],
    () => api.get<string>(`/file/${fileId}/html`).then(({ data }) => data),
    {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      enabled: Boolean(accessToken),

      onError: () =>
        UIKit.showToast(`Fetching document content ${fileId} failed`, {
          title: 'Error!',
          variant: ToastVariant.Warning,
          position: ToastPosition.BottomRight,
        }),
    }
  );
};

export const useHTML = (
  fileId: string,
  documentId: string,
  html?: string
): UseQueryResult<string> => {
  const { accessToken, api } = useAuth();

  return useQuery(
    [documentId, html],
    () =>
      api
        .post<string>(`/file/${fileId}/html`, [documentId, html], {
          headers: {
            'Content-Type': 'text/html',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(({ data }) => data),
    {
      staleTime: 1000 * 60 * 30,
      refetchOnWindowFocus: false,
      enabled: Boolean(accessToken) && Boolean(html),

      onError: () =>
        UIKit.showToast(`Fetching document content ${fileId} failed`, {
          title: 'Error!',
          variant: ToastVariant.Warning,
          position: ToastPosition.BottomRight,
        }),
    }
  );
};

const initialDownloadState = {
  downloadUrl: '',
  downloading: false,
  downloadReady: false,
  refetchInterval: 1000,
};

export const useDownload = (): {
  downloading: boolean;
  MAX_DOWNLOAD_LIMIT: number;
  download: (documents: Document[]) => void;
} => {
  const queueDownload = useFilesPost();

  const [downloadState, setDownloadState] =
    React.useState(initialDownloadState);

  const transferStatus = useTransferStatus(
    queueDownload?.data?.id,
    downloadState.refetchInterval
  );

  React.useEffect(() => {
    if (downloadState.downloadReady) {
      window.open(downloadState.downloadUrl, '_blank');
      transferStatus.remove();
    }
  }, [downloadState.downloadReady]);

  React.useEffect(() => {
    if (queueDownload.isLoading) {
      setDownloadState({
        downloadUrl: '',
        downloading: true,
        downloadReady: false,
        refetchInterval: 1000,
      });
    }
  }, [queueDownload.isLoading]);

  React.useEffect(() => {
    if (queueDownload.isSuccess) {
      if (queueDownload.data?.url) {
        setDownloadState((state) => ({
          ...state,
          downloadUrl: queueDownload.data.url,
        }));
      }
    }

    if (queueDownload.isError) {
      setDownloadState((state) => ({
        ...state,
        downloading: false,
      }));
    }
  }, [queueDownload.isSuccess, queueDownload.isError, queueDownload.data]);

  React.useEffect(() => {
    if (transferStatus.isError) {
      // Otherwise it would keep on retrying forever because refetchInterval is not 0
      setDownloadState((state) => ({
        ...state,
        refetchInterval: 0,
        downloading: false,
      }));
      return;
    }
    const status = transferStatus.data?.status;
    if (status === 'success') {
      setDownloadState((state) => ({
        ...state,
        downloading: false,
        refetchInterval: 0,
        downloadReady: true,
      }));
    } else if (status === 'error') {
      setDownloadState((state) => ({
        ...state,
        refetchInterval: 0,
        downloading: false,
      }));
    }
  }, [transferStatus.isError, transferStatus.data]);

  return {
    MAX_DOWNLOAD_LIMIT,
    downloading: downloadState.downloading,
    download: queueDownload.mutateAsync,
  };
};

export const useMergeAndDownload = (
  groupId: string,
  documents?: Document[]
): {
  downloading: boolean;
  download: () => void;
} => {
  const queueDownload = useMergeFiles(groupId, documents);

  const [downloadState, setDownloadState] =
    React.useState(initialDownloadState);

  const transferStatus = useTransferStatus(
    queueDownload?.data?.id,
    downloadState.refetchInterval
  );

  React.useEffect(() => {
    if (downloadState.downloadReady) {
      window.open(downloadState.downloadUrl, '_blank');
      queueDownload.remove();
      transferStatus.remove();
    }
  }, [downloadState.downloadReady]);

  React.useEffect(() => {
    if (queueDownload.isFetching) {
      setDownloadState({
        downloadUrl: '',
        downloading: true,
        downloadReady: false,
        refetchInterval: 1000,
      });
    }
  }, [queueDownload.isFetching]);

  React.useEffect(() => {
    if (queueDownload.isSuccess) {
      if (queueDownload.data?.url) {
        setDownloadState((state) => ({
          ...state,
          downloadUrl: queueDownload.data.url,
        }));
      }
    }

    if (queueDownload.isError) {
      setDownloadState((state) => ({
        ...state,
        downloading: false,
      }));
    }
  }, [queueDownload.isSuccess, queueDownload.isError, queueDownload.data]);

  React.useEffect(() => {
    if (transferStatus.isError) {
      // Otherwise it would keep on retrying forever because refetchInterval is not 0
      setDownloadState((state) => ({
        ...state,
        refetchInterval: 0,
        downloading: false,
      }));
      return;
    }
    const status = transferStatus.data?.status;
    if (status === 'success') {
      setDownloadState((state) => ({
        ...state,
        downloading: false,
        refetchInterval: 0,
        downloadReady: true,
      }));
    } else if (status === 'error') {
      setDownloadState((state) => ({
        ...state,
        refetchInterval: 0,
        downloading: false,
      }));
    }
  }, [transferStatus.isError, transferStatus.data]);

  return {
    downloading: downloadState.downloading,
    download: queueDownload.refetch,
  };
};
